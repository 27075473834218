<template>
  <div>
    <NavBar></NavBar>
    <div class="mt-60" >
      <h1 class="text-3xl text-center">About Integrity Homes</h1>
    </div>
    <section class="max-w-screen-lg mx-auto flex justify-between">
      <div class="w-1/2 px-4">
        <!-- Content for first div -->
        <div class="text-xl">See Our Projects</div>
        <div class="mt-4">
          <img width="482" src="images/k1.webp"/>
        </div>
      </div>
      <div class="w-1/2 px-4">
        <h3 class="text-xl">1 Kaponga Crescent, New Lynn</h3>
        <div class="mt-4">At Integrity Homes, we set the standard for exceptional construction across Auckland. With over a decade of industry experience, our latest project features a series of seven high-quality townhouses at 1 Kaponga Crescent, New Lynn. We are committed to combining luxurious residential living with practical commercial spaces, employing sustainable building practices and innovative technologies. Known for our reliability and meticulous craftsmanship, Integrity Homes enhances community value and consistently exceeds client expectations. For more details on our projects or partnership opportunities, please contact our office.</div>
      </div>
    </section>
    <section class="max-w-screen-lg mx-auto flex justify-between">
      <div class="w-1/2 px-4">
        <!-- Content for second div -->
        <h3 class="text-xl">History</h3>
        <div class="mt-4">Integrity Homes has always adhered to the tenet of quality first-time delivery, especially in today’s high-density residential projects, which have high requirements for design, construction and construction scheme time control. Our company has accumulated experience in recent years. Advancing with the times has accumulated a very unique experience. As of July 2019, Holmes Construction has completed several townhouses outside Auckland and Auckland. The independent villas have achieved very impressive results, some of which have participated. Project, development, design and construction of one-stop service, specialized individualized personalized design for different individuals, one-on-one construction program to meet the individual needs of customers, has won the praise of the majority of customers and a good society Return, from 2017 to 2019 July We have seen good results in projects completed outside of Auckland and Auckland.</div>
      </div>
      <div class="w-1/2 px-4">
        <div class="mt-6">
          <video class="elementor-video" 
            src="media/10cf54301522aa2c0f799c794333f74c.mp4" loop
            controls controlsList="nodownload"></video>
        </div>
      </div>
     
    </section>
    
    <section class="max-w-screen-lg mx-auto flex justify-between">
      <div class="w-1/2 px-4">
        <div class="mt-6">
          <img width="482" 
              src="images/220545878780969106-1-604x1024.jpg"
              class="attachment-large size-large" alt loading="lazy"
              srcset="images/220545878780969106-1-604x1024.jpg 604w, images/220545878780969106-1-177x300.jpg 177w, images/220545878780969106-1-768x1301.jpg 768w, images/220545878780969106-1.jpg 850w"
              sizes="(max-width: 604px) 100vw, 604px">
        </div>
      </div>
      <div class="w-1/2 px-4">
        <h3 class="text-xl">Qualification</h3>
        <div class="mt-4">Integrity Homes holds various certificates in construction such as New Zealand Building
Qualification (LBP) and Site 2 management qualifications. We have Insurance with
comprehensive insurance, such as Public Liability, Professional Indemnity, Statutory
Liability, and Employer Liability. Our team is with rich experience in building and efficient
management. It also has a good reputation and outstanding performance in the
mainstream construction industry. It has won the annual award of New Zealand Master
Builder rewards in year 2007, 2008, 2009, which is the recognition of our company’s
strength. There is a high evaluation in the local mainstream society and the Chinese
community. After more than 20 years of exploration and development, our company has
formed a complete set of development and construction. We pays great attention to
technical training, safety education and on-site emergency care training. All employees
are certified to work. The company has a strict quality management system and
schedule control system to ensure quality, safe and efficient completion of the works.</div>
      </div>
    </section>
    <FooterBar></FooterBar>

  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";

export default {
  name: 'About',
  components: {
    NavBar,
    FooterBar
  },
  mounted() {
    console.log('mounted')
  }
}
</script>

<style lang="scss">
  @import "../style/tailwind_copy.scss";
</style>

<style>



.post table td a,
.page table td a,
a,
.widget_calendar #wp-calendar td a,
.blog-classic .blog-post .post-header .post-title a:hover,
.blog-isotope .blog-post .blog-title:hover .blog-title-inner,
.team-item .team-thumb .hover a:hover,
#sidebar .widget a:hover,
#projects-filter a:hover,
.service-icon,
nav.main-navigation ul a:hover,
nav.main-navigation ul .current-menu-item > a,
nav.main-navigation ul .current-menu-ancestor > a {
  color: #e6772e;
}

.sticky .post-title a {
  color: #e6772e !important;
}

.read-more:hover {
  color: #eb955c;
}

footer .footer-widgets .widget_mc4wp_form_widget form input[type="submit"],
.sidebar .widget input[type="submit"],
.sidebar .widget button,
.blog-classic .blog-post .post-date,
.progress-bar,
.btn.btn-accent,
.team-item .team-thumb .hover {
  background-color: #e6772e;
}

.sidebar .widget input[type="submit"],
.sidebar .widget button {
  border-color: #e6772e;
}

::selection {
  background: #e6772e;
}

::-moz-selection {
  background: #e6772e;
}

.title-heading {
  text-align: center;
  margin-bottom: 50px;
}

/*article.project-post .image-placeholder img {*/
/*  height: 100%;*/
/*  width: 99%;*/
/*}*/

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 .07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}


</style>
